import React from "react";
import { DarkFooter as Footer } from "@components/footers";
import Layout from "@components/layout";
import SEO from "@components/seo";
import MenuBar from "@components/menuBar/MenuBar";
import Hero from "./Hero.js"
import get from 'lodash/get';
import ArticlePreview from "./article-preview";

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.nodes')

    return (
      <>
        <SEO title="Giftcards Sales and Technical Support" />
        <MenuBar/>
        <Hero/>
        <Layout>
          <ArticlePreview posts={posts} />
          <Footer/>
        </Layout>
      </>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  {
    allContentfulBlogPost(sort: {fields: publishDate, order: DESC}) {
      totalCount
      nodes {
        slug
        title
        description
        publishDate(formatString: "Do MMMM, YYYY")
        heroImage {
          description
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
      }
    }
  }
`
